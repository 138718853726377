// Version 1
import version1Bg from './version1/bg.png';
import version1GenderFemale from './version1/gender/female.png';
import version1GenderMale from './version1/gender/male.png';
import version1Host from './version1/host.png';
import version1RelationshipTypeCasual from './version1/relationshipType/casual.png';
import version1RelationshipTypeCoupleOfDates from './version1/relationshipType/coupleOfDates.png';
import version1RelationshipTypeSeriousRelationship from './version1/relationshipType/seriousRelationship.png';
import version1WomanPreference1829 from './version1/womanPreference/18-29.png';
import version1WomanPreference2045 from './version1/womanPreference/20-45.png';
import version1WomanPreference45 from './version1/womanPreference/45+.png';
import version1WomanTypeAthletic from './version1/womanType/athletic.png';
import version1WomanTypeCurvy from './version1/womanType/curvy.png';
import version1WomanTypePlump from './version1/womanType/plump.png';
// Version 2
import version2Bg from './version2/bg.png';
import version2GenderFemale from './version2/gender/female.png';
import version2GenderMale from './version2/gender/male.png';
import version2Host from './version2/host.png';
import version2RelationshipTypeCasual from './version2/relationshipType/casual.png';
import version2RelationshipTypeCoupleOfDates from './version2/relationshipType/coupleOfDates.png';
import version2RelationshipTypeSeriousRelationship from './version2/relationshipType/seriousRelationship.png';
import version2WomanPreference1829 from './version2/womanPreference/18-29.png';
import version2WomanPreference2045 from './version2/womanPreference/20-45.png';
import version2WomanPreference45 from './version2/womanPreference/45+.png';
import version2WomanTypeAthletic from './version2/womanType/athletic.png';
import version2WomanTypeCurvy from './version2/womanType/curvy.png';
import version2WomanTypePlump from './version2/womanType/plump.png';

export const Lander2Version1 = {
	bg: version1Bg,
	gender: {
		female: version1GenderFemale,
		male: version1GenderMale,
	},
	host: version1Host,
	relationshipType: {
		casual: version1RelationshipTypeCasual,
		coupleOfDates: version1RelationshipTypeCoupleOfDates,
		seriousRelationship: version1RelationshipTypeSeriousRelationship,
	},
	womanPreference: {
		'18-29': version1WomanPreference1829,
		'20-45': version1WomanPreference2045,
		'45+': version1WomanPreference45,
	},
	womanType: {
		athletic: version1WomanTypeAthletic,
		curvy: version1WomanTypeCurvy,
		plump: version1WomanTypePlump,
	},
};

export const Lander2Version2 = {
	bg: version2Bg,
	host: version2Host,
	gender: {
		female: version2GenderFemale,
		male: version2GenderMale,
	},
	relationshipType: {
		casual: version2RelationshipTypeCasual,
		coupleOfDates: version2RelationshipTypeCoupleOfDates,
		seriousRelationship: version2RelationshipTypeSeriousRelationship,
	},
	womanPreference: {
		'18-29': version2WomanPreference1829,
		'20-45': version2WomanPreference2045,
		'45+': version2WomanPreference45,
	},
	womanType: {
		athletic: version2WomanTypeAthletic,
		curvy: version2WomanTypeCurvy,
		plump: version2WomanTypePlump,
	},
};
