import './style.scss';

import { AnimatePresence } from 'framer-motion';
import { StateMachineProvider } from 'little-state-machine';
import { Outlet } from 'react-router-dom';

import { AnimateTextTyping, Expand, ProgressBar } from '@/components';
import { useWindowSize } from '@/hooks';

import { Content } from '../content';

interface ILander2 {
	percent: number;
	message: string;
	done: boolean;
	setDone: (done: boolean) => void;
	handleNext: () => void;
	versionId: string;
	landerId: string;
}

export const Lander2 = ({
	percent,
	message,
	done,
	setDone,
	handleNext,
	landerId,
	versionId,
}: ILander2) => {
	const lander = Content[landerId][versionId].lander;
	const { width } = useWindowSize();
	const barWidth = Math.min(width, lander.progressBarWidth ?? 0) - 104;

	return (
		<StateMachineProvider>
			<div className={`lander2__wrapper lander2__wrapper--v${versionId}`}>
				<img
					className={`lander2__wrapper__background--image`}
					src={lander.bgImage}
					alt="background"
				/>
				<div className={`lander2__wrapper__container`}>
					<ProgressBar
						percent={percent}
						baseColor={lander.progressBarBaseColor}
						progressColor={lander.progressBarProgressColor}
						hidePercentage={lander.hidePercentage}
						barWidth={barWidth}
					/>
					<div
						className={`lander2__wrapper__container__content lander2__wrapper__container__content--v${versionId}`}
					>
						<div className={`lander2__wrapper__container__content__host`}>
							<div
								className={`lander2__wrapper__container__content__host--image lander2__wrapper__container__content__host--image--v${versionId}`}
							>
								<img
									src={lander.hostImage}
									alt="host"
									className={`lander2__wrapper__container__content__host--image--avatar`}
								/>
							</div>
							<div
								className={`lander2__wrapper__container__content__host--bubble lander2__wrapper__container__content__host--bubble--v${versionId}`}
							>
								<AnimatePresence mode="wait">
									{message && (
										<AnimateTextTyping
											key={message}
											text={message}
											setDone={setDone}
											cursorBlinkerColor={lander.progressBarBaseColor}
										/>
									)}
								</AnimatePresence>
							</div>
						</div>
						<Expand expanded={done}>
							<Outlet context={{ handleNext, landerId, versionId }} />
						</Expand>
					</div>
				</div>
			</div>
		</StateMachineProvider>
	);
};
