import {
	Lander1Version1,
	Lander1Version2,
	Lander1Version3,
	Lander1Version4,
} from '@/assets/images/Lander1';
import { Lander2Version1, Lander2Version2 } from '@/assets/images/Lander2';
import { Content as IContent } from '@/model';

export const Content: IContent = {
	1: {
		1: {
			lander: {
				bgImage: Lander1Version1.bg,
				hostImage: Lander1Version1.host,
				progressBarBaseColor: 'rgba(222, 190, 190, 0.40)',
			},
			gender: {
				name: 'gender',
				options: [
					{
						name: 'gender',
						value: 'male',
						label: 'mand',
						image: Lander1Version1.gender.male,
					},
					{
						name: 'gender',
						value: 'female',
						label: 'kvinde',
						image: Lander1Version1.gender.female,
					},
				],
			},
			womanType: {
				name: 'womanType',
				options: [
					{
						name: 'womanType',
						value: 'dominant',
						label: 'dominerende',
						image: Lander1Version1.womanType.dominant,
					},
					{
						name: 'womanType',
						value: 'normal',
						label: 'normal',
						image: Lander1Version1.womanType.normal,
					},
					{
						name: 'womanType',
						value: 'submissive',
						label: 'underdanig',
						image: Lander1Version1.womanType.submissive,
					},
				],
			},
			preference: {
				name: 'preference',
				options: [
					{
						name: 'preference',
						value: 'vaginal',
						label: 'vaginal',
						image: Lander1Version1.preference.vaginal,
					},
					{
						name: 'preference',
						value: 'oral',
						label: 'oral',
						image: Lander1Version1.preference.oral,
					},
					{
						name: 'preference',
						value: 'anal',
						label: 'anal',
						image: Lander1Version1.preference.anal,
					},
				],
			},
		},
		2: {
			lander: {
				bgImage: Lander1Version2.bg,
				hostImage: Lander1Version2.host,
				progressBarBaseColor: '#fff',
			},
			gender: {
				name: 'gender',
				options: [
					{
						name: 'gender',
						value: 'male',
						label: 'mand',
						image: Lander1Version2.gender.male,
					},
					{
						name: 'gender',
						value: 'female',
						label: 'kvinde',
						image: Lander1Version2.gender.female,
					},
				],
			},
			womanType: {
				name: 'womanType',
				options: [
					{
						name: 'womanType',
						value: 'slim',
						label: 'spinkel',
						image: Lander1Version2.womanType.slim,
					},
					{
						name: 'womanType',
						value: 'normal',
						label: 'normal',
						image: Lander1Version2.womanType.normal,
					},
					{
						name: 'womanType',
						value: 'large',
						label: 'stor',
						image: Lander1Version2.womanType.large,
					},
				],
			},
			preference: {
				name: 'preference',
				options: [
					{
						name: 'preference',
						value: 'missionary',
						label: 'missionær',
						image: Lander1Version2.preference.missionary,
					},
					{
						name: 'preference',
						value: 'doggy',
						label: 'doggy',
						image: Lander1Version2.preference.doggy,
					},
					{
						name: 'preference',
						value: 'oral',
						label: 'oral',
						image: Lander1Version2.preference.oral,
					},
				],
			},
		},
		3: {
			lander: {
				bgImage: Lander1Version3.bg,
				hostImage: Lander1Version3.host,
				progressBarBaseColor: '#F376AC',
				progressBarProgressColor: '#7F1987',
			},
			gender: {
				name: 'gender',
				options: [
					{
						name: 'gender',
						value: 'male',
						label: 'mand',
						image: Lander1Version3.gender.male,
					},
					{
						name: 'gender',
						value: 'female',
						label: 'kvinde',
						image: Lander1Version3.gender.female,
					},
				],
			},
			womanType: {
				name: 'womanType',
				options: [
					{
						name: 'womanType',
						value: 'blonde',
						label: 'blondine',
						image: Lander1Version3.womanType.blonde,
					},
					{
						name: 'womanType',
						value: 'brown',
						label: 'brunette',
						image: Lander1Version3.womanType.brown,
					},
					{
						name: 'womanType',
						value: 'red',
						label: 'rødhåret',
						image: Lander1Version3.womanType.red,
					},
				],
			},
			preference: {
				name: 'preference',
				options: [
					{
						name: 'preference',
						value: 'At Yours',
						label: 'hos dig',
						image: Lander1Version3.preference.atYours,
					},
					{
						name: 'preference',
						value: 'At Theirs',
						label: 'hos dem',
						image: Lander1Version3.preference.atTheirs,
					},
					{
						name: 'preference',
						value: 'In Public',
						label: 'offentligt',
						image: Lander1Version3.preference.inPublic,
					},
				],
			},
		},
		4: {
			lander: {
				bgImage: Lander1Version4.bg,
				hostImage: Lander1Version4.host,
				progressBarBaseColor: '#756AB8',
				progressBarProgressColor: '#9CA7E4',
			},
			gender: {
				name: 'gender',
				options: [
					{
						name: 'gender',
						value: 'male',
						label: 'mand',
						image: Lander1Version4.gender.male,
					},
					{
						name: 'gender',
						value: 'female',
						label: 'kvinde',
						image: Lander1Version4.gender.female,
					},
				],
			},
			womanType: {
				name: 'womanType',
				options: [
					{
						name: 'womanType',
						value: 'dominant',
						label: 'dominerende',
						image: Lander1Version4.womanType.dominant,
					},
					{
						name: 'womanType',
						value: 'normal',
						label: 'normal',
						image: Lander1Version4.womanType.normal,
					},
					{
						name: 'womanType',
						value: 'submissive',
						label: 'underdanig',
						image: Lander1Version4.womanType.submissive,
					},
				],
			},
			preference: {
				name: 'preference',
				options: [
					{
						name: 'preference',
						value: 'home',
						label: 'hjemme',
						image: Lander1Version4.preference.home,
					},
					{
						name: 'preference',
						value: 'hotel',
						label: 'hotel',
						image: Lander1Version4.preference.hotel,
					},
					{
						name: 'preference',
						value: 'dinner',
						label: 'frokost',
						image: Lander1Version4.preference.dinner,
					},
				],
			},
		},
	},
	2: {
		1: {
			lander: {
				bgImage: Lander2Version1.bg,
				hostImage: Lander2Version1.host,
				progressBarBaseColor: 'rgba(87, 85, 85, 0.9)',
				progressBarProgressColor: '#DC9493',
				progressBarWidth: 1378,
				hidePercentage: true,
			},
			relationshipType: {
				name: 'relationshipType',
				options: [
					{
						name: 'relationshipType',
						value: 'casual',
						label: 'afslappet',
						image: Lander2Version1.relationshipType.casual,
					},
					{
						name: 'relationshipType',
						value: 'couple of dates',
						label: 'par dates',
						image: Lander2Version1.relationshipType.coupleOfDates,
					},
					{
						name: 'relationshipType',
						value: 'serious relationship',
						label: 'seriøst forhold',
						image: Lander2Version1.relationshipType.seriousRelationship,
					},
				],
			},
			gender: {
				name: 'gender',
				options: [
					{
						name: 'gender',
						value: 'male',
						label: 'mand',
						image: Lander2Version1.gender.male,
					},
					{
						name: 'gender',
						value: 'female',
						label: 'kvinde',
						image: Lander2Version1.gender.female,
					},
				],
			},
			womanType: {
				name: 'womanType',
				options: [
					{
						name: 'womanType',
						value: 'athletic',
						label: 'atletisk',
						image: Lander2Version2.womanType.athletic,
					},
					{
						name: 'womanType',
						value: 'curvy',
						label: 'kurvet',
						image: Lander2Version2.womanType.curvy,
					},
					{
						name: 'womanType',
						value: 'plump',
						label: 'buttet',
						image: Lander2Version2.womanType.plump,
					},
				],
			},
			womanPreference: {
				name: 'womanPreference',
				options: [
					{
						name: 'womanPreference',
						value: '18-29',
						label: '18-29',
						image: Lander2Version2.womanPreference['18-29'],
					},
					{
						name: 'womanPreference',
						value: '20-45',
						label: '20-45',
						image: Lander2Version2.womanPreference['20-45'],
					},
					{
						name: 'womanPreference',
						value: '45+',
						label: '45+',
						image: Lander2Version2.womanPreference['45+'],
					},
				],
			},
		},
		2: {
			lander: {
				bgImage: Lander2Version2.bg,
				hostImage: Lander2Version2.host,
				progressBarBaseColor: '#5D7087',
				progressBarProgressColor: '#3956eb',
				progressBarWidth: 1378,
				hidePercentage: true,
			},
			relationshipType: {
				name: 'relationshipType',
				options: [
					{
						name: 'relationshipType',
						value: 'casual',
						label: 'afslappet',
						image: Lander2Version2.relationshipType.casual,
					},
					{
						name: 'relationshipType',
						value: 'couple of dates',
						label: 'par dates',
						image: Lander2Version2.relationshipType.coupleOfDates,
					},
					{
						name: 'relationshipType',
						value: 'serious relationship',
						label: 'seriøst forhold',
						image: Lander2Version2.relationshipType.seriousRelationship,
					},
				],
			},
			gender: {
				name: 'gender',
				options: [
					{
						name: 'gender',
						value: 'male',
						label: 'mand',
						image: Lander2Version2.gender.male,
					},
					{
						name: 'gender',
						value: 'female',
						label: 'kvinde',
						image: Lander2Version2.gender.female,
					},
				],
			},
			womanType: {
				name: 'womanType',
				options: [
					{
						name: 'womanType',
						value: 'athletic',
						label: 'atletisk',
						image: Lander2Version2.womanType.athletic,
					},
					{
						name: 'womanType',
						value: 'curvy',
						label: 'kurvet',
						image: Lander2Version2.womanType.curvy,
					},
					{
						name: 'womanType',
						value: 'plump',
						label: 'buttet',
						image: Lander2Version2.womanType.plump,
					},
				],
			},
			womanPreference: {
				name: 'womanPreference',
				options: [
					{
						name: 'womanPreference',
						value: '18-29',
						label: '18-29',
						image: Lander2Version1.womanPreference['18-29'],
					},
					{
						name: 'womanPreference',
						value: '20-45',
						label: '20-45',
						image: Lander2Version1.womanPreference['20-45'],
					},
					{
						name: 'womanPreference',
						value: '45+',
						label: '45+',
						image: Lander2Version1.womanPreference['45+'],
					},
				],
			},
		},
	},
};
