import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ValidateUserEmail, ValidateUserEmailResponse } from '@/model';

import { axiosInstance } from '../axios';

const key = ['VALIDATE_USER_EMAIL'];

export const useValidateUserEmail = () =>
	useMutation({
		mutationKey: [...key],
		mutationFn: async ({
			email,
			click_id,
			lander_id,
			affiliate_id,
			source_id,
		}: ValidateUserEmail): Promise<ValidateUserEmailResponse> => {
			const { data } = await axiosInstance.post('auth/member/validate-email', {
				email,
				click_id,
				lander_id,
				affiliate_id,
				source_id,
			});
			return data;
		},
		onError: (error: AxiosError<{ errors: Record<string, string[]> }>) =>
			console.log(error),
	});
