import { PageConfig } from '@/model';

export const config: PageConfig = {
	dk: {
		1: {
			1: {
				pages: [
					{ id: 1, name: 'Gender', url: 'gender' },
					{ id: 2, name: 'Type of woman', url: 'woman-type' },
					{ id: 3, name: 'Age', url: 'age' },
					{ id: 4, name: 'Preference', url: 'preference' },
					{ id: 5, name: 'Zip code', url: 'zip-code' },
					{ id: 6, name: 'Username & password', url: 'user' },
					{ id: 7, name: 'Email', url: 'email' },
					{ id: 8, name: 'Success', url: 'success' },
				],
				messages: {
					gender:
						'Hej flotte, jeg hedder Hannah. Leder du efter en lokal hook-up? Fortæl mig venligst, er du:',
					'woman-type': 'Perfekt! Hvilken type kvinde søger du?',
					age: 'Det er lidt frækt. Hvor gammel er du søde?',
					preference:
						'Fantastisk, du vil finde mange passende kvinder. Når det kommer til sex, hvad foretrækker du?',
					'zip-code':
						'Også mig skat. Vi er der næsten. Foræl mig venligst din placering.',
					user: 'Du er heldig, vi har over 1000 piger i nærheden af dig! Opret brugernavn og adgangskode',
					email:
						'Du er nu klar til at møde vilde kvinder! Indtast blot din emailadresse, så vi kan give dig besked, når de begynder at kontakte dig. ',
					success: 'Tillykke, alle trin er gennemført!',
				},
			},
			2: {
				pages: [
					{ id: 1, name: 'Gender 2', url: 'gender' },
					{ id: 2, name: 'Type of woman 2', url: 'woman-type' },
					{ id: 3, name: 'Age 2', url: 'age' },
					{ id: 4, name: 'Preference 2', url: 'preference' },
					{ id: 5, name: 'Zip code 2', url: 'zip-code' },
					{ id: 6, name: 'Username & password 2', url: 'user' },
					{ id: 7, name: 'Email 2', url: 'email' },
					{ id: 8, name: 'Success 2', url: 'success' },
				],
				messages: {
					gender:
						'Hej flotte, jeg hedder Xenia. Jeg hører du leder efter en lokal sexpartner? Fortæl mig venligst, er du:',
					'woman-type': 'Fantastisk! Så hvilken type kvinde foretrækker du?',
					age: 'Det er sexet. Sig mig, hvor gammel er du? Indtast venligst din alder:',
					preference:
						'Perfekt, du vil finde mange matches. Bare fortæl mig hvad du foretrækker i sengen:',
					'zip-code':
						'Samme her! Næsten færdig. Fortæl mig venligst din placering:',
					user: 'Du er heldig, vi har over 1000 piger i nærheden af dig! Opret brugernavn og adgangskode',
					email:
						'Det er blevet tid til at finde nogle kvinder. Hyg dig! Bare giv mig din emailadresse, så vi kan give dig besked når de begynder at kontakte dig. ',
					success: 'Tillykke, alle trin er gennemført!',
				},
			},
			3: {
				pages: [
					{ id: 1, name: 'Gender 3', url: 'gender' },
					{ id: 3, name: 'Type of woman 3', url: 'woman-type' },
					{ id: 3, name: 'Age 3', url: 'age' },
					{ id: 4, name: 'Preference 3', url: 'preference' },
					{ id: 5, name: 'Zip code 3', url: 'zip-code' },
					{ id: 6, name: 'Username & password 3', url: 'user' },
					{ id: 7, name: 'Email 3', url: 'email' },
					{ id: 8, name: 'Success 3', url: 'success' },
				],
				messages: {
					gender: 'Jeg er Victoria, din lokale guide til sex på få minutter. Fortæl mig venligst, er du:',
					'woman-type': 'Perfekt! Hvilken type kvinde søger du?',
					age: 'Dem har vi mange af her! Bare fortæl mig hvor gammel du er? Indtast venligst din alder:',
					preference:
						'Fantastisk, du vil finde mange passende kvinder. Hvordan foretrækker du at mødes?',
					'zip-code':
						'Det lyder godt. En ting mere. Fortæl mig venligst din placering:',
					user: 'Wow, vi har mange medlemmer i dit område. Opret brugernavn og adgangskode',
					email:
						'En sidste ting før du er klar. Fortæl mig venligst din emailadresse, så vi kan give dig besked, når kvinder begynder at kontakte dig.',
					success: 'Tillykke, alle trin er gennemført!',
				},
			},
			4: {
				pages: [
					{ id: 1, name: 'Gender 4', url: 'gender' },
					{ id: 3, name: 'Type of woman 4', url: 'woman-type' },
					{ id: 3, name: 'Age 4', url: 'age' },
					{ id: 4, name: 'Preference 4', url: 'preference' },
					{ id: 5, name: 'Zip code 4', url: 'zip-code' },
					{ id: 6, name: 'Username & password 4', url: 'user' },
					{ id: 7, name: 'Email 4', url: 'email' },
					{ id: 8, name: 'Success 4', url: 'success' },
				],
				messages: {
					gender:
						'Hej, jeg hedder Charlotte. Lad mig hjælpe dig med at finde kærligheden lokalt. Kan du venligst fortælle mig, er du?',
					'woman-type':
						'En stærk mand, fantastisk! Så hvilken type kvinde behøver du?',
					age: 'Det er virkelig frækt. Hvor gammel er du? Indtast venligst din alder:',
					preference:
						'Fantastisk, du vil finde mange passende kvinder. Når det kommer til det første møde, foretrækker du:',
					'zip-code':
						'Godt valg. Næsten i mål... Fortæl mig venligst din placering:',
					user: 'Vi har mere end 100 kvinder inden for 10km fra din placering. Opret brugernavn og adgangskode:',
					email:
						'Åh, den vigtigste del! Jeg har brug for din emailadresse, så vi kan give dig besked, når kvinder kontakter dig.',
					success: 'Tillykke, alle trin er gennemført!',
				},
			},
		},
		2: {
			1: {
				pages: [
					{ id: 1, name: 'Relationship Type', url: 'relationship-type' },
					{ id: 2, name: 'Gender', url: 'gender' },
					{ id: 3, name: 'Age', url: 'age' },
					{ id: 4, name: 'Type of woman', url: 'woman-type' },
					{ id: 5, name: 'Woman Preference', url: 'woman-preference' },
					{ id: 6, name: 'Zip code', url: 'zip-code' },
					{ id: 7, name: 'Email', url: 'email' },
					{ id: 8, name: 'Username', url: 'username' },
					{ id: 9, name: 'Matches', url: 'matches' },
					{ id: 10, name: 'Password', url: 'password' },
					{ id: 11, name: 'Success', url: 'success' },
				],
				messages: {
					'relationship-type':
						'Hej, jeg hedder Karla, jeg er her for at hjælpe dig med at finde de bedste matches. Hvilke slags relation leder du efter?',
					gender: 'Er du en mand eller kvinde?',
					age: 'Hvor gammel er du?',
					'woman-type': 'Hvilken type kvindekrop foretrækker du?',
					'woman-preference':
						'Fantastisk. Jeg leder efter de bedst egnede matches. Hvilken alder kvinde søger du?',
					'zip-code':
						'I hvilken radius vil du søge? Indtast blot dit postnummer, du kan indstille radius senere. Hvor bor du?',
					email:
						'Udfyld venligst din email, så jeg kan holde dig orienteret om nye matches. ',
					username: 'Indtast venligst dit brugernavn',
					matches:
						'Vidunderligt. Jeg har allerede fundet en masse matches til dig!',
					password:
						'Fantastisk! Vælg nu en adgangskode. Det skal være mindst 6 tegn langt.',
					success: 'Tillykke, alle trin er gennemført!',
				},
			},
			2: {
				pages: [
					{ id: 1, name: 'Relationship Type 2', url: 'relationship-type' },
					{ id: 2, name: 'Gender 2', url: 'gender' },
					{ id: 3, name: 'Age 2', url: 'age' },
					{ id: 4, name: 'Type of woman 2', url: 'woman-type' },
					{ id: 5, name: 'Woman Preference 2', url: 'woman-preference' },
					{ id: 6, name: 'Zip code 2', url: 'zip-code' },
					{ id: 7, name: 'Email 2', url: 'email' },
					{ id: 8, name: 'Username 2', url: 'username' },
					{ id: 9, name: 'Matches 2', url: 'matches' },
					{ id: 10, name: 'Password 2', url: 'password' },
					{ id: 11, name: 'Success 2', url: 'success' },
				],
				messages: {
					'relationship-type':
						'Hej, jeg hedder Anna, jeg er her for at hjælpe dig med at finde de bedste matches. Hvilken slags relation lede du efter?',
					gender: 'Er du en mand eller kvinde?',
					age: 'Hvor gammel er du?',
					'woman-type': 'Hvilken kvindelig kropstype foretrækker du?',
					'woman-preference':
						'Fantastisk. Jeg leder efter de bedst egnede matches. Hvilken alder foretrækker du til kvinden?',
					'zip-code':
						'I hvilken radius vil du søge? Indtast blot dit postnummer, du kan indstille radius senere. Hvor bor du?',
					email:
						'Udfyld venligst din email, så jeg kan holde dig orienteret om nye matches. ',
					username: 'Indtast venligst dit brugernavn',
					matches:
						'Vidunderligt. Jeg har allerede fundet en masse matches til dig!',
					password:
						'Fantastisk! Vælg nu en adgangskode. Det skal være mindst 6 tegn langt.',
					success: 'Tillykke, alle trin er gennemført!',
				},
			},
		},
	},
};
